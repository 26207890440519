import React, { useCallback, useMemo, useState } from "react";
import { Scheduler } from "@bitnoi.se/react-scheduler";
import "@bitnoi.se/react-scheduler/dist/style.css";
import { useCompanyRentByCarData } from "../../../../Hooks/useCompanyRentByCarData";
import { useTranslation } from "react-i18next";
import { RentContracts } from "../../../../Types";
import dayjs_fr from "dayjs/locale/fr";
import dayjs_ar from "dayjs/locale/ar";
import moment from "moment";

function FleetDashboardScheduler() {
  const { contracts } = useCompanyRentByCarData();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const colors = [
    "#1abc9c",
    "#2ecc71",
    "#3498db",
    "#9b59b6",
    "#34495e",
    "#e67e22",
    "#e74c3c",
    "#d35400",
    "#e17055",
    "#fd79a8",
    "#74b9ff",
    "#eb4d4b",
    "#1abc9c",
    "#2ecc71",
    "#3498db",
    "#9b59b6",
    "#34495e",
    "#e67e22",
    "#e74c3c",
    "#d35400",
    "#e17055",
    "#fd79a8",
    "#74b9ff",
    "#eb4d4b",
  ];

  const contractToDisplay = useMemo(() => {
    return contracts.map((contract, index) => {
      return {
        id: contract.fleetID,
        label: {
          title: (
            <div className="flex flex-col">
              <span className="font-bold">
                {contract.manufacturer} {contract.modal}
              </span>

              <span>
                {contract.registrationNumber.replaceAll("/", ` ${t("TU")} `)}
              </span>
            </div>
          ),
          subtitle: `${t(contract.color)} | ${t(contract.transmission)} | ${t(contract.fuel)}`,
          icon: "https://stock.bmw.co.uk/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcmdEIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--3c82428f21543eb40240e1d41a4b91e372987315/i4.png",
        },
        data: contract.RentContract.map((rent: RentContracts) => {
          return {
            id: rent.rentContractID,
            startDate: new Date(rent.startDate),
            endDate: new Date(rent.endDate),
            occupancy: 11111,
            title: `${rent.client.fullName || rent.client.denomination}`,
            subtitle: `${moment(rent.startDate).format("DD/MM/YYYY HH:MM")} -> ${moment(rent.endDate).format("DD/MM/YYYY HH:MM")}`,
            bgColor: colors[index],
          };
        }),
      };
    });
  }, [contracts, t]);

  const [range, setRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const handleRangeChange = useCallback((range: any) => {
    setRange(range);
  }, []);

  const isDateInRange = (
    projectStartDate: string,
    projectEndDate: string,
    rangeStartDate: string,
    rangeEndDate: string,
  ) => {
    const startDate = new Date(projectStartDate).getTime();
    const endDate = new Date(projectEndDate).getTime();
    const rangeStart = new Date(rangeStartDate).getTime();
    const rangeEnd = new Date(rangeEndDate).getTime();

    return (
      (startDate >= rangeStart && startDate <= rangeEnd) ||
      (endDate >= rangeStart && endDate <= rangeEnd) ||
      (startDate <= rangeStart && endDate >= rangeEnd)
    );
  };

  const filteredSchedulerData: any = contractToDisplay.map((person) => ({
    ...person,
    data: person.data.filter((project: any) =>
      isDateInRange(
        project.startDate,
        project.endDate,
        new Date(range.startDate).toISOString(),
        new Date(range.endDate).toISOString(),
      ),
    ),
  }));

  const translations = [
    {
      id: "fr",
      lang: {
        feelingEmpty: "Vide",
        free: "Libre",
        loadNext: "Charger Suivant",
        loadPrevious: "Charger Précédent",
        over: "Terminé",
        taken: "Pris",
        topbar: {
          filters: "Filtres",
          next: "Suivant",
          prev: "Précédent",
          today: "Aujourd'hui",
          view: "Voir",
        },
        search: "Rechercher",
        week: "Semaine",
      },
      translateCode: "fr",
      dayjsTranslations: dayjs_fr,
    },
    {
      id: "ar",
      lang: {
        feelingEmpty: "فراغ",
        free: "مجاني",
        loadNext: "تحميل التالي",
        loadPrevious: "تحميل السابق",
        over: "انتهى",
        taken: "محجوز",
        topbar: {
          filters: "فلاتر",
          next: "التالي",
          prev: "السابق",
          today: "اليوم",
          view: "عرض",
        },
        search: "بحث",
        week: "أسبوع",
      },
      translateCode: "ar",
      dayjsTranslations: dayjs_ar,
    },
  ];

  return (
    <div
      className=" w-3/4 md:w-full flex  min-h-96 bg-white rounded-2xl   "
      dir="ltr"
    >
      <Scheduler
        data={filteredSchedulerData}
        onRangeChange={handleRangeChange}
        config={{
          zoom: 1,
          filterButtonState: -1,
          showTooltip: false,
          translations,
          showThemeToggle: false,
          isFiltersButtonVisible: false,
          lang: language,
        }}
      />
    </div>
  );
}

export default FleetDashboardScheduler;
