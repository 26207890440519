import React, { useState } from "react";
import { useEnumsData, useFleetData } from "../../../../Hooks";
import { Formik, FormikHelpers } from "formik";
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Result,
  Select,
} from "antd";
import FormItem from "antd/es/form/FormItem";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Axios } from "../../../../Config";
import { enumsToSelectOptions } from "../../../../Utils";
import moment from "moment/moment";
import { useMediaQuery } from "@mui/material";
import dayjs from "dayjs";

export interface InitialValuesT {
  places: number | null;
  numberHorses: number | null;
  vin: string | null;
  fuel: string | null;
  plateRight: number | null;
  plateLeft: number | null;
  manufacturer: string | null;
  modal: string | null;
  transmission: string | null;
  firstRegistration: Date | string | null;
  type: string | null;
  color: string | null;
}

function AddFleetContent() {
  const { enums, isLoading: isEnumsLoading } = useEnumsData();
  const { fleet } = useFleetData();
  const [isBtnLoading, setBtnIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const { t } = useTranslation();
  const initialValues: InitialValuesT = {
    places: null,
    numberHorses: null,
    vin: null,
    fuel: null,
    plateRight: null,
    plateLeft: null,
    manufacturer: null,
    modal: null,
    firstRegistration: null,
    transmission: null,
    type: null,
  };

  const onSubmitHandler = async (
    values: InitialValuesT,
    formikHelpers: FormikHelpers<InitialValuesT>,
  ) => {
    try {
      setBtnIsLoading(true);
      const data = {
        ...values,
        registrationNumber: `${values.plateLeft}/${values.plateRight}`,
      };
      if (
        fleet?.some(
          (car) =>
            car.registrationNumber ===
            `${values.plateLeft}/${values.plateRight}`,
        )
      ) {
        toast.info(t("CAR_EXISTS"));
      } else {
        await Axios.post("/enterprise/fleet", data);
        formikHelpers.setValues(initialValues);
        setIsSuccess(true);
      }
      setBtnIsLoading(false);
    } catch (e: any) {
      setBtnIsLoading(false);
      toast.error(
        t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
    }
  };
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  const filterOption = (
    input: string,
    option?: { label: string; value: string },
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div className="w-full  overflow-scroll p-10 flex flex-col items-center">
      {!isSuccess ? (
        <div className=" w-full md:w-3/4 ">
          <Formik
            initialValues={initialValues}
            onSubmit={(values, formikHelpers) =>
              onSubmitHandler(values, formikHelpers)
            }
          >
            {({ values, setFieldValue, submitForm, setFieldError, errors }) => (
              <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 14 }}
                layout={isSmallDevice ? "vertical" : "horizontal"}
                disabled={false}
                initialValues={values}
                onValuesChange={(fieldKeyValue) => {
                  const fieldKey = Object.keys(fieldKeyValue)[0];
                  const fieldValue = fieldKeyValue[fieldKey];

                  if (
                    fieldKey === "plateRight" ||
                    (fieldKey === "plateLeft" &&
                      fleet?.some(
                        (car) =>
                          car.registrationNumber ===
                          `${values.plateLeft}/${values.plateRight}`,
                      ))
                  ) {
                    setFieldError("plateRight", "car exists");
                  }

                  setFieldValue(fieldKey, fieldValue);
                }}
              >
                <FormItem label={t("car_number")}>
                  <InputNumber
                    name="plateLeft"
                    min={0}
                    maxLength={3}
                    max={999}
                    controls={false}
                    value={values.plateLeft}
                    onChange={(value) => {
                      setFieldValue("plateLeft", value);
                    }}
                  />
                  <span className="text-[#6E6E72] mx-4">{t("TU")}</span>
                  <InputNumber
                    name="plateRight"
                    min={0}
                    status={errors.plateRight && "error"}
                    maxLength={4}
                    max={9999}
                    controls={false}
                    value={values.plateRight}
                    onChange={(value) => {
                      setFieldValue("plateRight", value);
                    }}
                  />
                </FormItem>
                <FormItem label={t("VIN")} name="vin">
                  <Input
                    minLength={17}
                    maxLength={17}
                    value={values.vin || ""}
                    placeholder="0XXXX00XXXX000000"
                  />
                </FormItem>
                <FormItem label={t("manufacturer")} name="manufacturer">
                  <Select
                    showSearch
                    placeholder={t("manufacturer")}
                    optionFilterProp="children"
                    value={values.manufacturer || ""}
                    filterOption={filterOption}
                    style={{ width: "100%" }}
                    options={enumsToSelectOptions(enums?.["AutoManufacturers"])}
                  />
                </FormItem>

                <FormItem label={t("car_model")} name="modal">
                  <Input
                    placeholder={t("car_model")}
                    value={values.modal || ""}
                  />
                </FormItem>
                <FormItem label={t("type")} name="type">
                  <Select
                    showSearch
                    placeholder={t("type")}
                    optionFilterProp="children"
                    value={values.type || ""}
                    filterOption={filterOption}
                    style={{ width: "100%" }}
                    options={enumsToSelectOptions(enums?.["vehicleTypes"])}
                  />
                </FormItem>
                <FormItem label={t("car_color")} name="color">
                  <Select
                    showSearch
                    placeholder={t("car_color")}
                    optionFilterProp="children"
                    value={values.color || ""}
                    filterOption={filterOption}
                    style={{ width: "100%" }}
                    options={enumsToSelectOptions(enums?.["CarColors"])}
                  />
                </FormItem>
                <FormItem label={t("nb_places")} name="places">
                  <InputNumber
                    controls={false}
                    value={values.places}
                    min={0}
                    placeholder={t("nb_places")}
                  />
                </FormItem>
                <FormItem label={t("power")} name="numberHorses">
                  <InputNumber
                    controls={false}
                    value={values.numberHorses}
                    min={0}
                    placeholder={t("power")}
                    addonAfter={t("horse_power")}
                  />
                </FormItem>

                <FormItem label={t("fuel")} name="fuel">
                  <Select
                    placeholder={t("fuel")}
                    value={values.fuel || ""}
                    style={{ width: "100%" }}
                    options={enumsToSelectOptions(enums?.["FuelTypes"])}
                    loading={isEnumsLoading}
                  />
                </FormItem>
                <FormItem label={t("first_registration")}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    maxDate={dayjs(new Date())}
                    onChange={(_, day) => {
                      setFieldValue(
                        "firstRegistration",
                        moment(day, "DD/MM/YYYY").toISOString(),
                      );
                    }}
                  />
                </FormItem>

                <FormItem label={t("transmission")} name="transmission">
                  <Select
                    placeholder={t("transmission")}
                    style={{ width: "100%" }}
                    value={values.transmission || ""}
                    options={enumsToSelectOptions(enums?.["TransmissionTypes"])}
                    loading={isEnumsLoading}
                  />
                </FormItem>
                <div className="w-full flex justify-center">
                  <Button
                    type="primary"
                    className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white mt-4 !mx-auto"
                    onClick={submitForm}
                    loading={isBtnLoading}
                    disabled={
                      !values.plateRight ||
                      !values.plateLeft ||
                      !values.type ||
                      !values.transmission ||
                      !values.firstRegistration ||
                      !values.manufacturer ||
                      !values.modal ||
                      !values.fuel ||
                      !values.numberHorses ||
                      !values.places
                    }
                  >
                    {t("add_fleet").toUpperCase()}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        <Result
          status="success"
          title={t("car_added_success")}
          extra={[
            <Button
              type="primary"
              className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white mt-4 !mx-auto"
              onClick={() => setIsSuccess(false)}
            >
              {t("back")}
            </Button>,
          ]}
        />
      )}
    </div>
  );
}

export default AddFleetContent;
