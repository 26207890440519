import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useFleetData } from "../../../../../Hooks";
import {
  Button,
  DatePicker,
  Divider,
  Empty,
  Form,
  Input,
  Select,
  Spin,
  Switch,
} from "antd";
import { useFormikContext } from "formik";
import { RentInitialValues } from "./index";
import { SelectedCarDescription } from "./SelectedCarDescription";
import { t } from "i18next";
import { Drivers } from "../../../../../Types";
import { toast } from "react-toastify";
import { Axios } from "../../../../../Config";
import moment from "moment";
import dayjs from "dayjs";
import { SelectedDriverDescription } from "./SelectedDriverDescription";
import FormItem from "antd/es/form/FormItem";
import { useCompanyDriversData } from "../../../../../Hooks/useCompanyDriversData";
import { useTranslation } from "react-i18next";

function SelectDriversToRent({
  setCurrentStepper,
}: {
  setCurrentStepper: Dispatch<SetStateAction<number>>;
}) {
  const {
    i18n: { language },
  } = useTranslation();
  const { fleet, isLoading: isFleetLoading } = useFleetData();
  const { values, setFieldValue } = useFormikContext<RentInitialValues>();
  const [selectedDrivers, setSelectedDrivers] = useState<Drivers[] | []>([]);
  const [isBtnLoading, setBtnIsLoading] = useState<boolean>(false);
  const { companyDrivers, isLoading, mutate } = useCompanyDriversData();
  const [idNumber, setIdNumber] = useState<string>("");
  const [dateOfBirth, setDateOfBirth] = useState<string | undefined>(undefined);

  const driversAsOptions = useMemo(() => {
    return companyDrivers?.map((driver) => {
      return {
        value: driver.driverID,
        label: (
          <div className="flex gap-5 md:justify-between flex-col justify-center  py-4 md:flex-row  ">
            <div className="flex gap-4 ">
              <div className="flex flex-col px-5 my-auto">
                <div className="text-base font-bold tracking-tight leading-6 text-gray-900">
                  {driver?.fullName}
                </div>
                <div className="text-xs font-medium tracking-tight text-slate-400">
                  {driver?.driverID}
                </div>
              </div>
            </div>
            <div className="flex flex-col px-5 my-auto text-xs font-medium tracking-tight text-right text-slate-400">
              <div>
                {t("idNumber")} : <b> {driver?.idNumber}</b>
              </div>
              <div>
                {t("licenceNumber")} : <b> {driver?.licenceNumber}</b>
              </div>
            </div>
          </div>
        ),
      };
    });
  }, [companyDrivers]);

  if (isFleetLoading) {
    return (
      <div className="w-full h-fit flex flex-col gap-4 items-center">
        <Spin />
      </div>
    );
  }

  const handleSubmit = () => {
    const drivers = selectedDrivers.map((driver, index) => {
      return {
        DriverID: driver.driverID,
        driverType: index === 0 ? "FIRST" : "SECOND",
      };
    });
    setFieldValue("drivers", drivers).then(() => {
      setCurrentStepper((prevState) => prevState + 1);
    });
  };

  const searchForDriverHandler = async () => {
    try {
      setBtnIsLoading(true);
      const params = new URLSearchParams({
        idNumber,
        dateOfBirth: dateOfBirth || "",
      });

      const driver = (await Axios.get("/driver", { params }).then(
        (res) => res.data.message,
      )) as Drivers[] | [];

      if (!driver.length) {
        toast.info(t("DRIVER_NOT_FOUND"));
      } else {
        if (
          selectedDrivers.some(
            (drivers) => drivers.driverID === driver?.[0].driverID,
          )
        ) {
          toast.info(t("DRIVER_ALREADY_SELECTED"));
        } else {
          setSelectedDrivers((prevState) => [...prevState, ...driver]);
          setIdNumber("");
          setDateOfBirth(undefined);
        }
      }

      setBtnIsLoading(false);
    } catch (e: any) {
      toast.error(
        t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
      setBtnIsLoading(false);
    }
  };

  const handleCompanyDriver = (driverId: string) => {
    if (selectedDrivers.some((drivers) => drivers.driverID === driverId)) {
      toast.info(t("DRIVER_ALREADY_SELECTED"));
    } else {
      const driver =
        companyDrivers?.filter((driver) => driver.driverID === driverId) || [];
      if (driver.length) {
        setSelectedDrivers((prevState) => [...prevState, ...driver]);
      }
    }
  };

  return (
    <div className="w-full h-fit flex flex-col gap-4 items-center">
      {fleet?.length ? (
        <div className="w-full flex flex-col gap-4 items-center">
          <h3 className="text-sm font-bold">
            {t("selected_car").toUpperCase()}
          </h3>
          <SelectedCarDescription
            selectedCar={
              fleet?.filter((car) => car.fleetID === values.fleetID)[0]
            }
          />
        </div>
      ) : null}

      <Divider />
      <FormItem name="isRentWithDriver" label={t("isRentWithDriver")}>
        <Switch
          checkedChildren={t("yes")}
          unCheckedChildren={t("no")}
          value={values.contract.isRentWithDriver}
          onChange={(checked) => {
            setFieldValue("contract.isRentWithDriver", checked);
          }}
        />
      </FormItem>
      {values.contract.isRentWithDriver ? (
        <div className="w-full md:w-2/3 md:max-w-2/3self-center flex gap-5     leading-[150%] max-md:flex-wrap max-w-full">
          <div className="flex flex-col flex-1 grow shrink-0 basis-0 w-fit">
            <div className="flex gap-5 justify-between  mt-4 text-sm font-medium tracking-tight rounded-xl  text-slate-400 max-md:px-5">
              <Select
                direction={language === "ar" ? "rtl" : "ltr"}
                placeholder={t("select_drivers")}
                style={{ width: "100%", height: "fit-content" }}
                allowClear
                loading={isLoading}
                disabled={isLoading || selectedDrivers.length === 2}
                options={driversAsOptions}
                onChange={(value) => {
                  handleCompanyDriver(value);
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full md:w-2/3 flex flex-col md:flex-row gap-4 justify-center items-center ">
          <Form.Item label={t("idNumber")}>
            <Input
              value={idNumber || ""}
              placeholder={t("idNumber")}
              disabled={selectedDrivers.length === 2}
              onChange={({ target: { value } }) => {
                setIdNumber(value);
              }}
            />
          </Form.Item>
          <Form.Item label={t("dateOfBirth")}>
            <DatePicker
              value={
                dateOfBirth
                  ? dayjs(dateOfBirth, "YYYY-MM-DD").add(1, "day")
                  : undefined
              }
              format="DD/MM/YYYY"
              maxDate={dayjs(new Date())}
              disabled={selectedDrivers.length === 2}
              onChange={(_, date) => {
                console.log(moment(date).toISOString());
                setDateOfBirth(moment(date, "DD/MM/YYYY").toISOString());
              }}
            />
          </Form.Item>
          <Button
            type="primary"
            className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white  "
            loading={isBtnLoading}
            disabled={selectedDrivers.length === 2 || !idNumber || !dateOfBirth}
            onClick={searchForDriverHandler}
          >
            {t("search_driver").toUpperCase()}
          </Button>
        </div>
      )}

      <Divider />

      {selectedDrivers.length ? (
        <>
          <div>
            <Button
              type="primary"
              className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white mb-4  "
              onClick={() => {
                setSelectedDrivers([]);
              }}
            >
              {t("clear_drivers").toUpperCase()}
            </Button>
          </div>
          {selectedDrivers.map((selectedDriver, index) => {
            return (
              <SelectedDriverDescription
                selectedDriver={selectedDriver}
                isFirst={index === 0}
                key={selectedDriver.driverID}
              />
            );
          })}
        </>
      ) : (
        <Empty />
      )}

      <div>
        <Button
          type="primary"
          className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white  "
          disabled={selectedDrivers.length < 1}
          onClick={handleSubmit}
        >
          {t("submit").toUpperCase()}
        </Button>
      </div>
    </div>
  );
}

export default SelectDriversToRent;
