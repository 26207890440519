import { Tag } from "antd";
import moment from "moment";
import { Fragment } from "react";

export const CashAccountsColumns = (t: any) => [
  {
    title: t("accountName").toUpperCase(),
    dataIndex: "name",
    key: "name",
    align: "center",
    render: (value: any) => value.toUpperCase(),
  },
  {
    title: t("balance").toUpperCase(),
    dataIndex: "balance",
    key: "balance",
    width: 130,
    align: "center",
    render: (value: any) => (
      <Tag color="green">
        {" "}
        {Number(value).toFixed(3)} {t("TND")}
      </Tag>
    ),
  },
  {
    title: t("updatedAt").toUpperCase(),
    dataIndex: "updatedAt",
    key: "balance",
    width: 140,
    align: "center",
    render: (value: any) => (
      <Tag>{moment(value).format("DD/MM/YYYY HH:MM")}</Tag>
    ),
  },
  {
    title: t("compte_agence").toUpperCase(),
    dataIndex: "agence",
    key: "agence",
    width: 150,
    align: "center",
    render: (value: any) =>
      value ? (
        <Tag color="blue">{t("OUI")}</Tag>
      ) : (
        <Tag color="cyan">{t("NON")}</Tag>
      ),
  },
];

export const CashTransactionsColumns = (t: any) => [
  {
    title: t("type").toUpperCase(),
    dataIndex: "type",
    key: "type",
    align: "center",
    render: (value: any) =>
      value === "INCOME" ? (
        <Tag color="green">{t(value)}</Tag>
      ) : value === "TRANSFER" ? (
        <Tag color="orange">{t(value)}</Tag>
      ) : (
        <Tag color="red">{t(value)}</Tag>
      ),
  },

  {
    title: t("amount").toUpperCase(),
    dataIndex: "amount",
    key: "amount",
    align: "center",
    render: (value: any) => (
      <Tag color="blue">
        {Number(value).toFixed(3)} {t("TND")}
      </Tag>
    ),
  },
  {
    title: t("description").toUpperCase(),
    dataIndex: "description",
    key: "description",
    align: "center",
    render: (value: any) => {
      const textValue = value.split("<br>");
      return (
        <div className="w-full text-left">
          {textValue.map((text: string, index: number) => {
            return (
              <Fragment key={index}>
                <span> {text} </span> <br />
              </Fragment>
            );
          })}
        </div>
      );
    },
  },
  {
    title: t("DATE").toUpperCase(),
    dataIndex: "createdAt",
    key: "createdAt",

    align: "center",
    render: (value: any) => (
      <Tag>{moment(value).format("DD/MM/YYYY HH:MM")}</Tag>
    ),
  },
  {
    title: t("month").toUpperCase(),
    dataIndex: "createdAt",
    key: "createdAt",

    align: "center",
    render: (value: any) => (
      <span className="font-bold">
        {moment(value).format("MMMM YYYY").toUpperCase()}
      </span>
    ),
  },
  {
    title: t("created_by").toUpperCase(),
    dataIndex: "createdBy",
    key: "createdBy",

    align: "center",
    render: (value: any) => value.name + " " + value.lastName,
  },
];
