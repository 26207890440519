import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { Button, Input } from "antd";
import { Link } from "react-router-dom";
import { useState } from "react";
import { ForgotPassHandlerPropsT } from "../../../Types";
import { ForgotPassHandler } from "../../../Services";
import { toast } from "react-toastify";
import { ForgetPasswordValidation } from "../../../Validation";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

export const ForgotPasswordForm = () => {
  const { t } = useTranslation();
  const initialFormState = { email: "", newPassword: null, code: null };

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const onSubmitHandler = async (values: ForgotPassHandlerPropsT) => {
    try {
      setIsLoading(true);

      const userResponse = await ForgotPassHandler(values);
      toast.success(t(userResponse.data?.message["RESPONSE_CODE"]));
      setIsSuccess(true);
      setIsLoading(false);
    } catch (e: any) {
      setIsLoading(false);
      toast.error(
        t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
    }
  };

  return (
    <Formik
      initialValues={initialFormState}
      validationSchema={ForgetPasswordValidation}
      onSubmit={onSubmitHandler}
      isInitialValid={false}
    >
      {({
        errors,
        setFieldValue,
        isValid,
        submitForm,
        handleChange,
        handleBlur,
        values,
      }) => {
        return isSuccess ? (
          <div className="flex flex-col gap-4">
            <Input
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              placeholder={t("auth_email")}
              disabled={true}
              size="large"
              status={errors.email ? "error" : ""}
            />

            <Input.Password
              name="newPassword"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.newPassword || ""}
              size="large"
              placeholder={t("auth_password")}
              status={errors.newPassword ? "error" : ""}
              iconRender={(visible) =>
                visible ? (
                  <EyeTwoTone rev={undefined} />
                ) : (
                  <EyeInvisibleOutlined rev={undefined} />
                )
              }
            />

            <div className="w-full flex justify-center items-center">
              <Input.OTP
                variant="filled"
                size="large"
                value={values.code || ""}
              />
            </div>
            <div className="w-full flex justify-between border-t border-t-[#E5E5E5] flex-col  mb-auto  ">
              <Button
                type="primary"
                className="mb-5 !h-10 !bg-[#333333] !hover:bg-[#585757] !text-white"
                onClick={submitForm}
                disabled={!isValid || isLoading}
                loading={isLoading}
              >
                {t("send")}
              </Button>
            </div>
          </div>
        ) : (
          <>
            <Input
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              placeholder={t("auth_email")}
              size="large"
              status={errors.email ? "error" : ""}
            />

            <h3 className="mt-2 mb-5 mx-2 w-full text-sm  text-[#ff4d4f]">
              {errors.email && t(errors.email)}
            </h3>

            <div className="w-full flex justify-between border-t border-t-[#E5E5E5] flex-col  mb-auto">
              <h3 className=" text-xs  ml-3 cursor-pointer mb-8 mt-4 w-full text-center ">
                {t("auth_remember_your_password")}{" "}
                <Link to="/login" className="text-[#f46438] font-medium">
                  {t("login_title")}
                </Link>
              </h3>
              <Button
                type="primary"
                className="mb-5 !h-10 !bg-[#333333] !hover:bg-[#585757] !text-white"
                onClick={submitForm}
                disabled={!isValid || isLoading}
                loading={isLoading}
              >
                {t("send")}
              </Button>
            </div>
          </>
        );
      }}
    </Formik>
  );
};
