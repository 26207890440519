import React, { useMemo, useRef, useState } from "react";
import { Table, Tag, Tooltip } from "antd";
import { fleetListingColumns } from "./FleetListingColumns";
import { useFleetData } from "../../../../../Hooks";
import Search from "antd/es/input/Search";
import {
  CloseOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { toast } from "react-toastify";
import { Axios } from "../../../../../Config";
import { ColumnGroupType } from "antd/es/table";
import { Fleet } from "../../../../../Types";
import { ColumnType } from "antd/lib/table";

function FleetListingContent() {
  const { fleet, isLoading, mutate } = useFleetData();
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [showAvailableToday, setShowAvailableToday] = useState(false);
  const dataToDisplay = useMemo(() => {
    return search
      ? fleet &&
          fleet.filter((car) => {
            return Boolean(
              car.registrationNumber
                ?.toLowerCase()
                .includes(search.toLowerCase()) ||
                car.modal?.toLowerCase().includes(search.toLowerCase()),
            );
          })
      : fleet;
  }, [fleet, search]);

  const availableTodayCount = useMemo(() => {
    return fleet
      ? fleet.filter((car) => {
          return car.RentContract.every((rent) => {
            const now = moment();
            const todayEnd = moment().endOf("day");
            const rentStartDate = moment(rent.startDate);
            const rentEndDate = moment(rent.endDate);

            return rentEndDate.isBefore(now) || rentStartDate.isAfter(todayEnd);
          });
        })
      : [];
  }, [fleet]);

  const asyncToast = useRef<any>();

  const handleRemoveFleet = async (fleetID: string) => {
    try {
      asyncToast.current = toast.loading(t("DELETE_FLEET_ONGOING"));
      await Axios.put("/enterprise/fleet/delete", { fleetID }).then(() => {
        mutate();
      });
      toast.update(asyncToast.current, {
        render: t("DELETE_FLEET_SUCCESS_MESSAGE"),
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } catch (e: any) {
      toast.update(asyncToast.current, {
        render: t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };
  const fleetColumns: (ColumnGroupType<Fleet> | ColumnType<Fleet>)[] = [
    ...fleetListingColumns,
    {
      title: t("actions"),
      dataIndex: "fleetID",
      render: (value: string) => (
        <div className="w-full flex justify-center items-center ">
          <DeleteOutlined onClick={() => handleRemoveFleet(value)} />
        </div>
      ),
    },
  ];

  return (
    <div className="w-full  overflow-scroll p-10">
      <div className="flex gap-4 mb-4 justify-between">
        <div className="flex gap-4 justify-center items-center">
          <Search
            placeholder={t("search")}
            allowClear
            onSearch={(value) => {
              setSearch(value);
            }}
            style={{ width: 304 }}
          />
          <Tooltip title={t("plate_search_info")}>
            <InfoCircleOutlined />
          </Tooltip>
        </div>
        <div className="flex gap-4">
          {fleet?.length ? (
            <div>
              <Tag color="cyan">
                {t("fleet_count")} :{fleet.length}
              </Tag>
            </div>
          ) : null}
          {fleet?.length ? (
            <div>
              <Tag
                color="cyan"
                onClick={() => {
                  setShowAvailableToday(true);
                }}
              >
                {t("fleet_available_today")} :{availableTodayCount.length}
              </Tag>
            </div>
          ) : null}
          {showAvailableToday ? (
            <div>
              <Tag
                onClick={() => {
                  setShowAvailableToday(false);
                }}
              >
                <CloseOutlined />
              </Tag>
            </div>
          ) : null}
        </div>
      </div>
      <Table
        rowKey="fleetID"
        size="small"
        loading={isLoading}
        dataSource={
          showAvailableToday ? availableTodayCount : dataToDisplay || []
        }
        columns={fleetColumns}
        rootClassName="h-2/3 min-h-2/3 "
        bordered={true}
      />
    </div>
  );
}

export default FleetListingContent;
