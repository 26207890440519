import { ColumnGroupType } from "antd/es/table";
import { ColumnType } from "antd/lib/table";

import moment from "moment/moment";
import { Tag } from "antd";
import { RentContracts } from "../../../../../Types";
import React from "react";

export const RentListingColumns: (
  t: any,
) => (ColumnGroupType<RentContracts> | ColumnType<RentContracts>)[] = (t) => [
  {
    title: t("date_of_creations"),
    dataIndex: "createdAt",
    align: "center",
    key: "createdAt",
    render: (value) => moment(value).format("DD/MM/YYYY HH:MM"),
  },
  {
    title: t("client_name"),
    dataIndex: "client",
    align: "center",
    key: "client",
    render: (value) => {
      return value.fullName || value.denomination;
    },
  },
  {
    title: t("start_date"),
    align: "center",
    dataIndex: "startDate",
    key: "startDate",
    render: (value) => moment(value).format("DD/MM/YYYY HH:MM"),
  },

  {
    title: t("end_date"),
    align: "center",
    dataIndex: "endDate",
    key: "endDate",
    render: (value) => moment(value).format("DD/MM/YYYY HH:MM"),
  },
  {
    title: t("status"),
    dataIndex: "rentContractID",
    align: "center",
    key: "rentContractID",
    render: (value, record) => {
      if (
        Number(
          moment(record.endDate).diff(moment(record.startDate), "hours") / 24,
        ) < 0
      ) {
        return (
          <div className="w-full flex justify-center items-center">
            <Tag color="red">{t("CANCELED")}</Tag>
          </div>
        );
      }

      if (Boolean(moment(new Date()).diff(moment(record.startDate)) < 0)) {
        return (
          <div className="w-full flex justify-center items-center">
            <Tag color="red">{t("PLANED")}</Tag>
          </div>
        );
      }
      if (
        Boolean(
          moment(new Date()).diff(moment(record.startDate)) > 0 &&
            moment(new Date()).diff(moment(record.endDate)) < 0,
        )
      ) {
        return (
          <div className="w-full flex justify-center items-center">
            <Tag color="orange">{t("ON_GOING")}</Tag>
          </div>
        );
      }
      if (
        Boolean(
          moment(new Date()).diff(record.endDate) > 0 && record.isReturned,
        )
      ) {
        return (
          <div className="w-full flex justify-center items-center">
            <Tag color="green">{t("FINISHED")}</Tag>
          </div>
        );
      }
      if (
        moment(record.endDate).isBefore(
          moment(new Date()).add(1, "hour"),
          "hour",
        )
      ) {
        return (
          <span className="mt-1 flex items-center justify-center">
            <Tag color="magenta">{t("DELAYED_RETURN")}</Tag>
          </span>
        );
      }
    },
  },
  {
    title: t("dayPrice"),
    dataIndex: "dayPrice",
    align: "center",
    key: "dayPrice",
    render: (value) => `${value} ${t("TND")}`,
  },
  {
    title: t("days"),
    dataIndex: "rentContractID",
    align: "center",
    key: "nbDays",
    render: (_, record) => {
      if (
        Number(
          moment(record.endDate).diff(moment(record.startDate), "hours") / 24,
        ) < 0
      ) {
        return "-";
      }

      return `${Number(moment(record.endDate).diff(moment(record.startDate), "hours") / 24).toFixed(3)} ${t(
        "days",
      )}`;
    },
  },

  {
    title: t("isRentWithDriver"),
    dataIndex: "isRentWithDriver",
    align: "center",
    key: "isRentWithDriver",
    render: (value) => {
      return (
        <div className="w-full flex justify-center items-center">
          {value ? (
            <Tag color="geekblue">{t("yes")}</Tag>
          ) : (
            <Tag color="magenta">{t("no")}</Tag>
          )}
        </div>
      );
    },
  },
  {
    title: t("created_by"),
    dataIndex: "createdBy",
    align: "center",
    key: "createdBy",
    render: (value) => {
      return `${value.name} ${value.lastName}`;
    },
  },
  {
    title: t("paymentMethod"),
    dataIndex: "paymentMethod",
    align: "center",
    key: "paymentMethod",
    render: (value) => {
      return t(value);
    },
  },
  {
    title: t("agenceName"),
    dataIndex: "agence",
    align: "center",
    key: "agenceName",
    render: (value) => {
      return value ? value.agenceName : "-";
    },
  },
  {
    title: t("comment"),
    dataIndex: "comment",
    align: "center",
    key: "comment",
    render: (value) => {
      return value;
    },
  },
];
