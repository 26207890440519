import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, Image, QRCode } from "antd";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { fileUrlHandler } from "../../../Utils";

export const RentPrintableContract = ({
  contract,
  showPrint = true,
  isConsultant = false,
}: any) => {
  const { t } = useTranslation();
  const print = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => print.current,
  });

  if (!contract) {
    return null;
  }

  const { ContractDrivers, company, rentedCar } = contract;

  const firstDriver = ContractDrivers?.filter(
    (driver: any) => driver?.driverType === "FIRST",
  )?.[0]?.driver;
  const secondDriver = ContractDrivers?.filter(
    (driver: any) => driver?.driverType !== "FIRST",
  )?.[0]?.driver;

  return (
    <div className="p-8 !bg-white rounded-xl overflow-x-scroll ">
      {showPrint && (
        <div className="flex gap-4 flex-col md:flex-row mb-7 ">
          <Button
            type="primary"
            className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white"
            onClick={handlePrint}
          >
            {t("print_contract").toUpperCase()}
          </Button>
        </div>
      )}
      <div ref={print} className="flex flex-col min-w-[800px]">
        <div className="flex flex-col pt-5 pr-9 pb-2.5 font-bold leading-4 uppercase  max-md:pr-5">
          <div className="flex gap-5 self-center ml-10 text-xs tracking-normal text-justify text-neutral-400 max-md:flex-wrap">
            <div className="flex flex-auto gap-3 max-md:flex-wrap">
              <Image
                preview={false}
                width={166}
                style={{ aspectRatio: "16/9" }}
                src={fileUrlHandler(company?.logo?.name || "")}
                crossOrigin="anonymous"
              />
              <div className="shrink-0 self-start bg-zinc-800 h-full rounded-[46.5px] w-[3px]" />
              <div className="my-auto w-[450px] text-xs">
                <span className="text-zinc-800 ">
                  {company.rsLatin || company.rsArabic}
                </span>
                <br />
                <span className="text-xs  text-neutral-400">
                  Adresse: {company.mainAddress}
                </span>
                <br />
                <span className="text-xs  text-neutral-400">
                  Matricule Fiscale : {company.VAT}
                </span>
                <br />
                <span className="text-xs  text-neutral-400">
                  Tel : {company.phone}
                </span>
                <br />
                <span className="text-xs  text-neutral-400">
                  Email : {company?.email?.toLowerCase()}
                </span>
              </div>
            </div>

            <img
              alt=""
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/ded3866e6a0ff93fbab3ed08aeed5c249c0d4cf5abbc4da0a8e39850d008641f?apiKey=47918fdd8d134febb6c66661b288dec3&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/ded3866e6a0ff93fbab3ed08aeed5c249c0d4cf5abbc4da0a8e39850d008641f?apiKey=47918fdd8d134febb6c66661b288dec3&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ded3866e6a0ff93fbab3ed08aeed5c249c0d4cf5abbc4da0a8e39850d008641f?apiKey=47918fdd8d134febb6c66661b288dec3&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/ded3866e6a0ff93fbab3ed08aeed5c249c0d4cf5abbc4da0a8e39850d008641f?apiKey=47918fdd8d134febb6c66661b288dec3&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/ded3866e6a0ff93fbab3ed08aeed5c249c0d4cf5abbc4da0a8e39850d008641f?apiKey=47918fdd8d134febb6c66661b288dec3&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ded3866e6a0ff93fbab3ed08aeed5c249c0d4cf5abbc4da0a8e39850d008641f?apiKey=47918fdd8d134febb6c66661b288dec3&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/ded3866e6a0ff93fbab3ed08aeed5c249c0d4cf5abbc4da0a8e39850d008641f?apiKey=47918fdd8d134febb6c66661b288dec3&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/ded3866e6a0ff93fbab3ed08aeed5c249c0d4cf5abbc4da0a8e39850d008641f?apiKey=47918fdd8d134febb6c66661b288dec3&"
              className="shrink-0 self-end mt-14 w-16 aspect-[2.63] max-md:mt-10"
            />
          </div>
          <div className="flex gap-5 mt-5 text-base tracking-normal text-right text-white max-md:flex-wrap">
            <div className="flex flex-auto gap-5 justify-between px-10 py-3.5 bg-zinc-800 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
              <img
                alt=""
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/9c23590f051f1df2d899561e7f89e4b55a5ecf95cad0ec0d157bc1632cbd7b6a?apiKey=47918fdd8d134febb6c66661b288dec3&"
                className="shrink-0 my-auto max-w-full aspect-[6.25] w-[172px]"
              />
              <div>
                عقد كراء
                <br />
                سيارة
              </div>
            </div>
            <QRCode
              value={contract.rentContractID}
              size={80}
              bordered={false}
            />
          </div>
          <div className="flex gap-5 pl-10 pt-5 text-xs font-bold tracking-normal leading-4 text-center uppercase w-[600px]  max-w-[600px] text-zinc-800">
            <div>IDENTIFIANT UNIQUE</div>
            <div className="  text-neutral-500">{contract.rentContractID}</div>
            <div>المعرف الوحيد</div>
          </div>
        </div>

        <div className="w-ful flex gap-6 justify-evenly px-6">
          <div className="flex flex-col mt-4      text-xs tracking-normal leading-4 text-black capitalize bg-white max-w-1/2 w-1/2 ">
            <div className="flex gap-5 justify-between px-1.5 py-2 text-xs font-bold tracking-normal leading-4 text-center text-white uppercase whitespace-nowrap rounded bg-zinc-800">
              <div>Véhicule</div>
              <div>السيارة</div>
            </div>
            <div className="flex gap-5 justify-between px-3 py-1.5 text-center border-b border-solid border-zinc-400">
              <div>Matricule :</div>
              <div className="self-start font-bold">
                {rentedCar.registrationNumber.replace("/", ` ${t("TU")} `)}
              </div>
              <div className="text-xs tracking-normal leading-4" dir={"rtl"}>
                الرقم المنجمي :
              </div>
            </div>
            <div className="flex gap-5 justify-between px-3 py-1.5 text-center border-b border-solid border-zinc-400">
              <div>VIN:</div>
              <div className="text-center font-bold">{rentedCar.vin}</div>
              <div
                className="self-stretch text-xs tracking-normal leading-4 text-center"
                dir={"rtl"}
              >
                الرقم في السلسلة :
              </div>
            </div>
            <div className="flex gap-5 justify-between px-3 py-1.5 text-center border-b border-solid border-zinc-400">
              <div>Marque :</div>
              <div className="my-auto font-bold">{rentedCar.manufacturer}</div>
              <div className="text-xs tracking-normal leading-4 " dir={"rtl"}>
                النوع :
              </div>
            </div>
            <div className="flex gap-5 justify-between px-3 py-1.5 text-center border-b border-solid border-zinc-400">
              <div>type :</div>
              <div className="my-auto text-center font-bold">
                {rentedCar.modal}
              </div>
              <div
                className="text-xs tracking-normal leading-4 text-center"
                dir={"rtl"}
              >
                الصنف :
              </div>
            </div>
            <div className="flex gap-5 justify-between px-2 py-2 mt-3 text-xs font-bold tracking-normal leading-4 text-center text-white uppercase rounded bg-zinc-800">
              <div>Durée de location</div>
              <div>مدة الكراء</div>
            </div>
            <div className="flex gap-5 justify-between px-3 py-1.5 text-center border-b border-solid border-zinc-400">
              <div className="self-stretch my-auto">Date début location :</div>
              <div className="self-stretch my-auto font-bold">
                {moment(contract.startDate).format("DD/MM/YYYY HH:MM")}
              </div>
              <div
                className="self-stretch text-xs tracking-normal leading-4"
                dir={"rtl"}
              >
                تاريخ بداية الكراء :
              </div>
            </div>
            <div className="flex gap-5 justify-between px-3 py-1.5 text-center border-b border-solid border-zinc-400">
              <div className="self-stretch my-auto">Date Fin location :</div>
              <div className="self-stretch my-auto text-center font-bold ">
                {moment(contract.endDate).format("DD/MM/YYYY HH:MM")}
              </div>
              <div
                className="self-stretch text-xs tracking-normal leading-4 text-center"
                dir={"rtl"}
              >
                تاريخ نهاية الكراء :
              </div>
            </div>
            <div className="flex gap-5 justify-between px-3 py-1.5 text-center border-b border-solid border-zinc-400">
              <div>Nombre de jours :</div>
              <div className="self-start font-bold">
                {moment(contract.endDate).diff(
                  moment(contract.startDate),
                  "day",
                )}
              </div>
              <div className="text-xs tracking-normal leading-4" dir={"rtl"}>
                عدد الأيام :
              </div>
            </div>
            {isConsultant ? null : (
              <div className="flex gap-5 justify-between px-3 py-1.5 text-center border-b border-solid border-zinc-400">
                <div>Prix :</div>
                <div className="text-center font-bold">
                  {contract.dayPrice} {t("TND")}
                </div>
                <div
                  className="text-xs tracking-normal leading-4 text-center"
                  dir={"rtl"}
                >
                  التسعيرة :
                </div>
              </div>
            )}
            <div className="flex gap-5 justify-between px-2.5 py-2 mt-3.5 text-xs font-bold tracking-normal leading-4 text-center text-white uppercase rounded bg-zinc-800">
              <div>ETAT Véhicule</div>
              <div>وضع المركبة</div>
            </div>
            <img
              alt=""
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/9068fdee111699763aa35deef0d34b2d536cc7f4ac1d7989b43aec48e3d37844?apiKey=47918fdd8d134febb6c66661b288dec3&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/9068fdee111699763aa35deef0d34b2d536cc7f4ac1d7989b43aec48e3d37844?apiKey=47918fdd8d134febb6c66661b288dec3&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9068fdee111699763aa35deef0d34b2d536cc7f4ac1d7989b43aec48e3d37844?apiKey=47918fdd8d134febb6c66661b288dec3&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/9068fdee111699763aa35deef0d34b2d536cc7f4ac1d7989b43aec48e3d37844?apiKey=47918fdd8d134febb6c66661b288dec3&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/9068fdee111699763aa35deef0d34b2d536cc7f4ac1d7989b43aec48e3d37844?apiKey=47918fdd8d134febb6c66661b288dec3&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9068fdee111699763aa35deef0d34b2d536cc7f4ac1d7989b43aec48e3d37844?apiKey=47918fdd8d134febb6c66661b288dec3&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/9068fdee111699763aa35deef0d34b2d536cc7f4ac1d7989b43aec48e3d37844?apiKey=47918fdd8d134febb6c66661b288dec3&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/9068fdee111699763aa35deef0d34b2d536cc7f4ac1d7989b43aec48e3d37844?apiKey=47918fdd8d134febb6c66661b288dec3&"
              className="mt-1.5 w-full aspect-[2.08]"
            />
            <div className="flex gap-5 justify-between px-3 py-1.5 text-center border-b border-solid border-zinc-400">
              <div className="text-right">Kilométrage départ :</div>
              <div className="my-auto text-center font-bold">
                {contract.kmCount} {t("KM")}
              </div>
              <div className="text-xs tracking-normal leading-4" dir={"rtl"}>
                الكيلومترات عند الكراء :
              </div>
            </div>
            <div className="flex gap-5 justify-between px-3 py-1.5 text-center border-b border-solid border-zinc-400">
              <div className="text-right">Kilométrage retour :</div>
              <div className="my-auto text-center font-bold">-</div>
              <div className="text-xs tracking-normal leading-4" dir={"rtl"}>
                الكيلومترات عند العودة :
              </div>
            </div>
            <div className="flex gap-5 justify-between px-3 py-1.5 text-center border-b border-solid border-zinc-400">
              <div className="text-right">Carburant :</div>
              <div className="self-start text-center font-bold">
                {contract.FuelCount}
              </div>
              <div className="text-xs tracking-normal leading-4" dir={"rtl"}>
                الوقود :
              </div>
            </div>
            <div className="flex gap-2 mt-4">
              <div className="text-right">Contrat Crée le</div>
              <div className="flex-auto font-bold">
                {moment(contract.createdAt).format("DD/MM/YYYY HH:MM:SS")}
              </div>
            </div>
            <div className="flex gap-1 mt-2.5">
              <div className="text-right">Contrat Crée Par</div>
              <div className="flex-auto font-bold">
                {contract.createdBy.name}{" "}
                {contract.createdBy.lastName.toUpperCase()}
              </div>
            </div>
            {isConsultant ? null : (
              <>
                <div className="mt-3.5 text-[8px] font-bold tracking-normal   text-center text-red-600 lowercase">
                  Le Kilométrage est limité à 400 KM/J tout excès est facturé à
                  la base de 200 millimes/KM
                </div>
                <div className="self-center mt-2 text-[8px] font-bold tracking-normal leading-4 text-center text-red-600 capitalize">
                  تقتصر المسافة المقطوعة على 400 كم/اليوم وأي زيادة في المسافة
                  المقطوعة يتم احتسابها بمقدار 200 مليم/كيلومتر{" "}
                </div>
                <div className="self-center mt-3.5 font-bold text-center uppercase">
                  Signature de l’agent et cachet de l’agence
                </div>
              </>
            )}
          </div>

          <div className="flex flex-col  mt-4     text-xs tracking-normal leading-4 text-black capitalize bg-white max-w-1/2 w-1/2 ">
            <div className="flex gap-5 justify-between px-2 py-2.5 text-xs font-bold tracking-normal leading-4 text-white uppercase whitespace-nowrap rounded bg-zinc-800">
              <div>client</div>
              <div className="text-center">الحريف</div>
            </div>
            <div className="justify-center items-center px-16 py-2 text-xs font-bold tracking-normal leading-4 text-center text-black capitalize border-b border-solid border-zinc-400">
              {contract.client.fullName || contract.client.denomination}
            </div>
            <div className="justify-center items-center px-16 py-2 text-xs font-bold tracking-normal leading-4 text-center text-black capitalize whitespace-nowrap border-b border-solid border-zinc-400">
              {contract.client.idNumber || contract.client.vatNumber}
            </div>

            <div className="flex gap-5 px-2.5 py-2 mt-3.5 text-xs font-bold tracking-normal leading-4 text-white uppercase rounded bg-zinc-800">
              <div className="flex-auto">premier conducteur</div>
              <div className="text-center">السائق الأول</div>
            </div>
            <div className="flex gap-5 justify-between px-3 py-1.5 text-center border-b border-solid border-zinc-400">
              <div>Nom et Prénom :</div>
              <div className="font-bold">
                ------
                {firstDriver?.fullName || "--------"}
              </div>
              <div dir={"rtl"}>الإسم و اللقب :</div>
            </div>
            <div className="flex gap-5 justify-between px-3 py-1.5 text-center border-b border-solid border-zinc-400">
              <div>date de naissance :</div>
              <div className="font-bold text-center">
                {firstDriver?.dateOfBirth
                  ? moment(firstDriver?.dateOfBirth).format("DD/MM/YYYY")
                  : "--------------"}
              </div>
              <div dir={"rtl"}>تاريخ الولادة :</div>
            </div>
            <div className="flex gap-5 justify-between px-3 py-1.5 text-center border-b border-solid border-zinc-400">
              <div className="flex flex-col self-stretch my-auto">
                <div>Identité :</div>
                <div className="mt-4  ">date de délivrance :</div>
              </div>
              <div className="flex flex-col self-stretch my-auto font-bold text-center whitespace-nowrap">
                <div>{firstDriver?.idNumber || "--------------"}</div>
                <div className="mt-4">
                  {firstDriver?.idIssueDate
                    ? moment(firstDriver?.idIssueDate).format("DD/MM/YYYY")
                    : "--------------"}
                </div>
              </div>
              <div className="flex flex-col self-stretch text-xs tracking-normal leading-4 text-center">
                <div dir={"rtl"}>الهوية :</div>
                <div className="mt-3.5" dir={"rtl"}>
                  تاريخ الإصدار :
                </div>
              </div>
            </div>
            <div className="flex gap-5 justify-between px-3 py-1.5 text-center border-b border-solid border-zinc-400">
              <div className="flex flex-col self-stretch my-auto">
                <div>Permis N :</div>
                <div className="mt-4  ">date de délivrance :</div>
              </div>
              <div className="flex flex-col self-stretch my-auto font-bold text-center whitespace-nowrap">
                <div>{firstDriver?.licenceNumber || "--------------"}</div>
                <div className="mt-4">
                  {firstDriver?.licenceIssueDate
                    ? moment(firstDriver?.licenceIssueDate).format("DD/MM/YYYY")
                    : "--------------"}
                </div>
              </div>
              <div className="flex flex-col self-stretch text-xs tracking-normal leading-4 text-center">
                <div dir={"rtl"}>رخصة القيادة :</div>
                <div className="mt-3.5" dir={"rtl"}>
                  تاريخ الإصدار :
                </div>
              </div>
            </div>
            <div className="flex gap-5 justify-between px-3 py-1.5 text-center border-b border-solid border-zinc-400">
              <div>téléphone :</div>
              <div className="font-bold text-center">
                {firstDriver?.phone || "--------------"}
              </div>
              <div dir={"rtl"}>الهاتف :</div>
            </div>

            <div className="flex gap-5 px-2.5 py-2 mt-3.5 text-xs font-bold tracking-normal leading-4 text-white uppercase rounded bg-zinc-800">
              <div className="flex-auto">deuxième conducteur</div>
              <div className="text-center">السائق الثاني</div>
            </div>
            <div className="flex gap-5 justify-between px-3 py-1.5 text-center border-b border-solid border-zinc-400">
              <div>Nom et Prénom :</div>
              <div className="font-bold">
                ------
                {secondDriver?.fullName || "--------"}
              </div>
              <div dir={"rtl"}>الإسم و اللقب :</div>
            </div>
            <div className="flex gap-5 justify-between px-3 py-1.5 text-center border-b border-solid border-zinc-400">
              <div>date de naissance :</div>
              <div className="font-bold text-center">
                {secondDriver?.dateOfBirth
                  ? moment(secondDriver?.dateOfBirth).format("DD/MM/YYYY")
                  : "--------------"}
              </div>
              <div dir={"rtl"}>تاريخ الولادة :</div>
            </div>
            <div className="flex gap-5 justify-between px-3 py-1.5 text-center border-b border-solid border-zinc-400">
              <div className="flex flex-col self-stretch my-auto">
                <div>Identité :</div>
                <div className="mt-4  ">date de délivrance :</div>
              </div>
              <div className="flex flex-col self-stretch my-auto font-bold text-center whitespace-nowrap">
                <div>{secondDriver?.idNumber || "--------------"}</div>
                <div className="mt-4">
                  {secondDriver?.idIssueDate
                    ? moment(secondDriver?.idIssueDate).format("DD/MM/YYYY")
                    : "--------------"}
                </div>
              </div>
              <div className="flex flex-col self-stretch text-xs tracking-normal leading-4 text-center">
                <div dir={"rtl"}>الهوية :</div>
                <div className="mt-3.5" dir={"rtl"}>
                  تاريخ الإصدار :
                </div>
              </div>
            </div>
            <div className="flex gap-5 justify-between px-3 py-1.5 text-center border-b border-solid border-zinc-400">
              <div className="flex flex-col self-stretch my-auto">
                <div>Permis N :</div>
                <div className="mt-4  ">date de délivrance :</div>
              </div>
              <div className="flex flex-col self-stretch my-auto font-bold text-center whitespace-nowrap">
                <div>{secondDriver?.licenceNumber || "--------------"}</div>
                <div className="mt-4">
                  {secondDriver?.licenceIssueDate
                    ? moment(secondDriver?.licenceIssueDate).format(
                        "DD/MM/YYYY",
                      )
                    : "--------------"}
                </div>
              </div>
              <div className="flex flex-col self-stretch text-xs tracking-normal leading-4 text-center">
                <div dir={"rtl"}>رخصة القيادة :</div>
                <div className="mt-3.5" dir={"rtl"}>
                  تاريخ الإصدار :
                </div>
              </div>
            </div>
            <div className="flex gap-5 justify-between px-3 py-1.5 text-center border-b border-solid border-zinc-400">
              <div>téléphone :</div>
              <div className="font-bold text-center">
                {secondDriver?.phone || "--------------"}
              </div>
              <div dir={"rtl"}>الهاتف :</div>
            </div>

            {isConsultant ? null : (
              <div className="flex gap-5 mt-2.5 text-[10px] font-bold tracking-normal">
                <div className="flex flex-col flex-1 self-start">
                  <div className="leading-relaxed text-zinc-600 text-[8px] font-bold">
                    J’ai lu et j’approuve les informations et les conditions de
                    vente
                  </div>
                  <div className="mt-3 text-center text-black uppercase   text-[8px] font-bold">
                    Signature premier conducteur
                  </div>
                </div>
                <div className="flex flex-col flex-1">
                  <div
                    className="leading-relaxed text-right capitalize text-zinc-600 text-[10px] font-bold"
                    dir={"rtl"}
                  >
                    لقد قرأت و وافقت على البيانات
                    <br />
                    وشروط البيع
                  </div>
                  <div className="mt-3 text-center text-black uppercase   text-[8px] font-bold">
                    Signature deuxième conducteur
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
