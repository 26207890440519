import { Modal, Popover, Table } from "antd";
import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";
import { ChromeFilled } from "@ant-design/icons";

interface PropsT {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<string | null>>;
  loginActivity: any;
}

function UserActivityHistoryModal({
  isOpen = false,
  setIsOpen,
  loginActivity,
}: PropsT) {
  const { t } = useTranslation();

  const columns = [
    {
      title: t("ip_address"),
      dataIndex: "ip",
      key: "ip",
    },
    {
      title: t("country"),
      dataIndex: "country",
      key: "country",
    },
    {
      title: t("continent"),
      dataIndex: "continent",
      key: "continent",
    },
    {
      title: t("region"),
      dataIndex: "region",
      key: "region",
    },
    {
      title: t("date"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data: string) => (
        <p>{moment(new Date(data)).format("DD/MM/YYYY HH:MM:SS")}</p>
      ),
    },
    {
      title: t("browser"),
      dataIndex: "browser",
      key: "browser",
      render: (browser: string) => {
        return (
          <div className="w-full h-full flex justify-center items-center">
            <Popover content={browser}>
              <ChromeFilled />
            </Popover>
          </div>
        );
      },
    },
  ];

  return isOpen ? (
    <Modal
      className=" !w-2/3"
      open={isOpen}
      title={t("activity-history")}
      footer={null}
      onCancel={() => {
        setIsOpen(null);
      }}
    >
      <div className="p-8 flex justify-center items-center ">
        <Table
          rootClassName="w-full"
          dataSource={loginActivity}
          columns={columns}
          size="small"
          showSorterTooltip={true}
          pagination={{ pageSizeOptions: ["10"] }}
          scroll={{ x: 500 }}
        />
      </div>
    </Modal>
  ) : null;
}

export default UserActivityHistoryModal;
