import React, { useMemo } from "react";
import { useAccountsStatsData } from "../../../../../Hooks/useAccountsStatsData";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import { useTranslation } from "react-i18next";

function CashManagementDashboardContent() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const {
    accountsStats,
    isLoading: isAccountsDataLoading,
    mutate: mutateStat,
  } = useAccountsStatsData();

  const companyCashFlow10Months = useMemo(() => {
    return accountsStats ? accountsStats.companyCashFlow10Months : null;
  }, [accountsStats]);

  const data = useMemo(() => {
    return {
      labels: companyCashFlow10Months
        ? companyCashFlow10Months.map((item: any) =>
            item.week.replaceAll("Week", t("Weeks")),
          )
        : [],
      datasets: [
        {
          label: t("INCOME"),
          data: companyCashFlow10Months
            ? companyCashFlow10Months.map((item: any) => item.revenues)
            : [],
          borderColor: "rgba(75, 192, 192, 1)",
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          fill: false,
        },
        {
          label: t("EXPENSE"),
          data: companyCashFlow10Months
            ? companyCashFlow10Months.map((item: any) => item.expenses)
            : [],
          borderColor: "rgba(255, 99, 132, 1)",
          backgroundColor: "rgba(255, 99, 132, 0.2)",
          fill: false,
        },
        {
          label: t("TRANSFER"),
          data: companyCashFlow10Months
            ? companyCashFlow10Months.map((item: any) => item.transfers)
            : [],
          borderColor: "rgba(153, 102, 255, 1)",
          backgroundColor: "rgba(153, 102, 255, 0.2)",
          fill: false,
        },
      ],
    };
  }, [companyCashFlow10Months, language]);

  const options: any = {
    plugins: {
      title: {
        display: true,
        text: t("expenses_income_transfer_5_weeks"),
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: t("Weeks"),
        },
      },
      y: {
        title: {
          display: true,
          text: t("Amount_TND"),
        },
      },
    },
  };

  if (isAccountsDataLoading) {
    return <div>loading</div>;
  }
  return (
    <div className="w-full flex flex-col p-6">
      <div className="  flex gap-4 flex-col md:flex-row md:flex-nowrap  ">
        <div className="flex-1  md:h-96  flex gap-4 flex-nowrap justify-center items-center">
          <Line
            data={data}
            options={options}
            height={220}
            title="vu des transaction par semine"
          />
        </div>
      </div>
    </div>
  );
}

export default CashManagementDashboardContent;
