import React, { useState } from "react";
import { FieldArray, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Result,
  Row,
  Select,
} from "antd";
import FormItem from "antd/es/form/FormItem";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Axios } from "../../../../../Config";
import { useClientsData } from "../../../../../Hooks/useClientsData";
import dayjs from "dayjs";
import { enumsToSelectOptions } from "../../../../../Utils";
import { useEnumsData } from "../../../../../Hooks";

interface Advance {
  amount: number;
  method: string;
  status: string;
}

interface InitialValuesT {
  startDate: string | null;
  endDate: string | null;
  totalPrice: number | null;
  pickupLocation: string | null;
  dropOffLocation: string | null;
  additionalNotes: string | null;
  status: string | null;
  clientID: string | null;
  advances: Advance[];
}

const validationSchema = Yup.object().shape({
  startDate: Yup.date().nullable().required("Start date is required"),
  endDate: Yup.date()
    .nullable()
    .required("End date is required")
    .min(Yup.ref("startDate"), "End date cannot be before start date"),
  totalPrice: Yup.number().nullable().required("Total price is required"),
  pickupLocation: Yup.string()
    .nullable()
    .required("Pickup location is required"),
  dropOffLocation: Yup.string()
    .nullable()
    .required("Drop-off location is required"),
  clientID: Yup.string().nullable().required("Client is required"),
  advances: Yup.array().of(
    Yup.object().shape({
      amount: Yup.number().required("Amount is required").min(0),
      method: Yup.string().required("Method is required"),
      status: Yup.string().required("Status is required"),
    }),
  ),
});

function AddReservationContent() {
  const { t } = useTranslation();

  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isBtnLoading, setBtnIsLoading] = useState<boolean>(false);
  const { clients, isLoading } = useClientsData();
  const { enums, isLoading: isEnumsLoading } = useEnumsData();
  const initialValues: InitialValuesT = {
    startDate: null,
    endDate: null,
    totalPrice: null,
    pickupLocation: null,
    dropOffLocation: null,
    additionalNotes: null,
    status: "CONFIRMED",
    clientID: null,
    advances: [],
  };

  const onSubmitHandler = async (
    values: any,
    formikHelpers: FormikHelpers<any>,
  ) => {
    try {
      setBtnIsLoading(true);
      const data = {
        ...values,
        startDate: values.startDate ? values.startDate.toISOString() : null,
        endDate: values.endDate ? values.endDate.toISOString() : null,
      };

      await Axios.post("/reservation/", data);
      formikHelpers.setValues(initialValues);
      setIsSuccess(true);
      setBtnIsLoading(false);
    } catch (e: any) {
      setBtnIsLoading(false);
      toast.error(
        t(
          e?.response?.data?.message["RESPONSE_CODE"] ||
            "SERVICE_UNAVAILABLE_MESSAGE",
        ),
      );
    }
  };

  const clientsToOptions = clients
    ? clients.map((client) => {
        return {
          label:
            client.fullName ||
            client.denomination ||
            client.vatNumber ||
            client.idNumber,
          value: client.clientID,
        };
      })
    : [];

  return (
    <div className="w-full overflow-scroll p-10">
      {!isSuccess ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmitHandler}
          validationSchema={validationSchema}
        >
          {({ values, submitForm, setFieldValue, isValid, dirty }) => {
            return (
              <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                disabled={false}
                initialValues={values}
                onValuesChange={(fieldKeyValue) => {
                  const fieldKey = Object.keys(fieldKeyValue)[0];
                  const fieldValue = fieldKeyValue[fieldKey];
                  setFieldValue(fieldKey, fieldValue);
                }}
              >
                <FormItem label={t("clients_lookup")} name="clientID">
                  <Row gutter={8}>
                    <Col flex="auto">
                      <Select
                        showSearch
                        allowClear
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        placeholder={t("clients_lookup")}
                        optionFilterProp="children"
                        value={values.clientID || ""}
                        options={clientsToOptions}
                        disabled={isLoading}
                        loading={isLoading}
                        onChange={(value) => {
                          setFieldValue("clientID", value);
                        }}
                        style={{ width: "100%" }}
                      />
                    </Col>
                  </Row>
                </FormItem>
                <Row gutter={2}>
                  <Col span={12}>
                    <FormItem name="startDate" label={t("start_date")}>
                      <DatePicker
                        showTime
                        format="YYYY-MM-DD HH:mm:ss"
                        minDate={dayjs(new Date())}
                        style={{ width: "100%" }}
                        value={
                          values.startDate ? moment(values.startDate) : null
                        }
                        onChange={(value) => setFieldValue("startDate", value)}
                      />
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem name="endDate" label={t("end_date")}>
                      <DatePicker
                        showTime
                        format="YYYY-MM-DD HH:mm:ss"
                        minDate={dayjs(new Date())}
                        style={{ width: "100%" }}
                        value={values.endDate ? moment(values.endDate) : null}
                        onChange={(value) => setFieldValue("endDate", value)}
                      />
                    </FormItem>
                  </Col>
                </Row>

                <Row gutter={2}>
                  <Col span={24}>
                    <FormItem name="totalPrice" label={t("totalPrice")}>
                      <InputNumber
                        controls={false}
                        addonAfter={t("TND")}
                        decimalSeparator={","}
                        style={{ width: "100%" }}
                      />
                    </FormItem>
                  </Col>
                  <Col span={24}>
                    <FormItem name="pickupLocation" label={t("pickupLocation")}>
                      <Input />
                    </FormItem>
                  </Col>
                </Row>

                <Row gutter={2}>
                  <Col span={24}>
                    <FormItem
                      name="dropOffLocation"
                      label={t("dropOffLocation")}
                    >
                      <Input />
                    </FormItem>
                  </Col>
                  <Col span={24}>
                    <FormItem name="status" label={t("status")}>
                      <Select
                        value={values.status}
                        onChange={(value) => setFieldValue("status", value)}
                        options={enumsToSelectOptions(
                          enums?.["ReservationStatus"],
                        )}
                      />
                    </FormItem>
                  </Col>
                </Row>

                <FormItem name="additionalNotes" label={t("additionalNotes")}>
                  <Input.TextArea rows={4} />
                </FormItem>
                <div className="w-full  md:w-5/6 mx-auto">
                  <FieldArray name="advances">
                    {({ push, remove }) => (
                      <>
                        {values.advances.map((advance, index) => (
                          <Row key={index} gutter={6}>
                            <Col span={8}>
                              <FormItem
                                name={`advances[${index}].amount`}
                                label={index === 0 ? t("amount") : ""}
                              >
                                <InputNumber
                                  controls={false}
                                  min={0}
                                  style={{ width: "100%" }}
                                  value={advance.amount}
                                  onChange={(value) =>
                                    setFieldValue(
                                      `advances[${index}].amount`,
                                      value,
                                    )
                                  }
                                />
                              </FormItem>
                            </Col>
                            <Col span={8}>
                              <FormItem
                                name={`advances[${index}].method`}
                                label={index === 0 ? t("method") : ""}
                              >
                                <Select
                                  value={advance.method}
                                  onChange={(value) =>
                                    setFieldValue(
                                      `advances[${index}].method`,
                                      value,
                                    )
                                  }
                                  options={enumsToSelectOptions(
                                    enums?.["paymentPasserelle"],
                                  )}
                                />
                              </FormItem>
                            </Col>
                            <Col span={6}>
                              <FormItem
                                name={`advances[${index}].status`}
                                label={index === 0 ? t("status") : ""}
                              >
                                <Select
                                  value={advance.status}
                                  onChange={(value) =>
                                    setFieldValue(
                                      `advances[${index}].status`,
                                      value,
                                    )
                                  }
                                  options={enumsToSelectOptions(
                                    enums?.["ReservationStatus"],
                                  )}
                                />
                              </FormItem>
                            </Col>
                            <Col span={2}>
                              <Button
                                type="dashed"
                                danger
                                icon={<MinusCircleOutlined />}
                                onClick={() => remove(index)}
                              ></Button>
                            </Col>
                          </Row>
                        ))}
                        <Button
                          type="dashed"
                          onClick={() =>
                            push({ amount: 0, method: "", status: "" })
                          }
                          block
                          icon={<PlusOutlined />}
                          className="mt-8"
                        >
                          {t("add_advance")}
                        </Button>
                      </>
                    )}
                  </FieldArray>
                </div>
                <div className="flex justify-center w-full">
                  <Button
                    type="primary"
                    onClick={submitForm}
                    loading={isBtnLoading}
                    disabled={!isValid || !dirty}
                    className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white mt-4 !mx-auto"
                  >
                    {t("add_reservation")}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Result
          status="success"
          title={t("reservation_added_success")}
          extra={[
            <Button
              type="primary"
              className="!h-9 !bg-[#333333] !hover:bg-[#585757] !text-xs !px-12 !text-white mt-4 !mx-auto"
              onClick={() => setIsSuccess(false)}
            >
              {t("back")}
            </Button>,
          ]}
        />
      )}
    </div>
  );
}

export default AddReservationContent;
