import React from "react";
import { t } from "i18next";
import { ColumnGroupType } from "antd/es/table";
import { ColumnType } from "antd/lib/table";
import { Fleet } from "../../../../../Types";
import moment from "moment";
import { Tag } from "antd";

export interface ExpandedDataType {
  key: React.Key;
  date: string;
  name: string;
  upgradeNum: string;
}

export const fleetListingColumns: (
  | ColumnGroupType<Fleet>
  | ColumnType<Fleet>
)[] = [
  {
    title: t("car_number"),
    dataIndex: "registrationNumber",
    key: "registrationNumber",
    render: (value: string) => {
      return (
        <Tag color="#2d3436" className="font-bold">
          {value.replace("/", ` ${t("TU")} `)}
        </Tag>
      );
    },
  },

  {
    title: t("manufacturer"),
    dataIndex: "manufacturer",
    render: (value: string) => value.replaceAll("_", " "),
  },
  {
    title: t("car_model"),
    dataIndex: "modal",
  },
  {
    title: t("first_registration"),
    dataIndex: "firstRegistration",
    render: (value: string) => moment(value).format("DD/MM/YYYY"),
  },
  {
    title: t("power"),
    dataIndex: "numberHorses",
    render: (value: string) => `${value} ${t("horse_power")}`,
  },
  {
    title: t("car_color"),
    dataIndex: "color",
    render: (value: string) => t(value),
  },
  {
    title: t("type"),
    dataIndex: "type",
    render: (value: string) => t(value),
  },
  {
    title: t("fuel"),
    dataIndex: "fuel",
    render: (value: string) => t(value),
  },

  {
    title: t("nb_places"),
    dataIndex: "places",
  },
  {
    title: t("VIN"),
    dataIndex: "vin",
  },
  {
    title: t("transmission"),
    dataIndex: "transmission",
    render: (value) => t(value),
  },
];
