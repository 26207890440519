import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../Store/store";
import { useSwiftSWR } from "../../Config";
import { LoadingPage } from "../../Pages/GeneralPages/LoadingPage";
import { userLoginAction, userLogoutAction } from "../../Store/actions";

interface State {
  authentication: {
    isAuthenticated: boolean;
    token: string;
  };
}

export const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const dispatch = useDispatch<AppDispatch>();

  const userStore = useSelector((state: State) => state.authentication);

  const isAuthenticated = userStore.isAuthenticated;

  const { data, isLoading, error, mutate } = useSwiftSWR("/authn/check", {
    refreshInterval: 1000 * 60 * 10,
    revalidateOnMount: false,
  });

  useEffect(() => {
    if (!isLoading && token) {
      if (error) {
        console.log(error);
        if (error.status === 401) {
          dispatch(userLogoutAction());
          return navigate("/login");
        }
        mutate().then(() => {
          dispatch(userLogoutAction());
          navigate("/login");
        });
      } else if (data && !isAuthenticated) {
        dispatch(userLoginAction(data.user));
        return navigate("/login");
      }
    }
  }, [data, error, token, dispatch, navigate, isAuthenticated]);

  if (isLoading) {
    return <LoadingPage />;
  }

  if (!token || !isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
};
