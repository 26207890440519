import React, { useMemo, useState } from "react";
import { Image, Radio, Table } from "antd";
import { useClientsData } from "../../../../../Hooks/useClientsData";
import {
  ClientsExpendableListingColumns,
  ClientsListingColumns,
} from "./ClientsListingColumns";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Search from "antd/es/input/Search";

function ClientsListingContent() {
  const { isLoading, clients } = useClientsData();

  const { t } = useTranslation();
  const columns = ClientsListingColumns(t);
  const user = useSelector((state: any) => state.authentication.user);

  if (!user.AgencesUsers.length) {
    columns.push({
      title: t("agenceName"),
      dataIndex: "agence",
      key: "agenceName",
      render: (value) => {
        return value ? value.agenceName : "-";
      },
    });
  }

  const [search, setSearch] = useState("");
  const [searchStatus, setSearchStatus] = useState<"ALL" | "pp" | "pm">("ALL");

  const dataToDisplay = useMemo(() => {
    let filterClients = clients || [];

    if (searchStatus === "ALL") {
      filterClients = clients;
    }

    if (searchStatus === "pp") {
      filterClients = filterClients.filter((client) => {
        return Boolean(client.clientType === "PP");
      });
    }
    if (searchStatus === "pm") {
      filterClients = filterClients.filter((client) => {
        return Boolean(client.clientType === "PM");
      });
    }

    return search
      ? filterClients.filter((client) => {
          return Boolean(
            client.vatNumber?.toLowerCase().includes(search.toLowerCase()) ||
              client.email?.toLowerCase().includes(search.toLowerCase()) ||
              client.idNumber?.toLowerCase().includes(search.toLowerCase()) ||
              client.fullName?.toLowerCase().includes(search.toLowerCase()) ||
              client.address?.toLowerCase().includes(search.toLowerCase()) ||
              client.phone?.toLowerCase().includes(search.toLowerCase()) ||
              client.userID?.toLowerCase().includes(search.toLowerCase()) ||
              client.clientID?.toLowerCase().includes(search.toLowerCase()) ||
              client.denomination?.toLowerCase().includes(search.toLowerCase()),
          );
        })
      : filterClients;
  }, [clients, search, searchStatus]);

  const expandedColumns = ClientsExpendableListingColumns(t);
  const expandedRowRender = (record: any) => {
    return (
      <div className="w-full flex flex-col">
        <Table
          columns={expandedColumns}
          dataSource={[record]}
          pagination={false}
          direction="rtl"
        />
        <div className="my-6 flex justify-evenly">
          <Image.PreviewGroup
            preview={{
              onChange: (current, prev) =>
                console.log(`current index: ${current}, prev index: ${prev}`),
            }}
          >
            {record?.idRecto?.name && (
              <Image
                width={400}
                height={250}
                title={"ID RECTO"}
                src={`${process.env.REACT_APP_API_ENDPOINT_BASE_URL}${process.env.REACT_APP_API_ENDPOINT_PREFIX}/media/${record.idRecto.name}`}
              />
            )}
            {record?.idVerso?.name && (
              <Image
                width={400}
                height={250}
                title={"ID VERSO"}
                src={`${process.env.REACT_APP_API_ENDPOINT_BASE_URL}${process.env.REACT_APP_API_ENDPOINT_PREFIX}/media/${record.idVerso.name}`}
              />
            )}
          </Image.PreviewGroup>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full  overflow-scroll p-10">
      <div className="flex gap-4 mb-4 justify-between flex-col md:flex-row min-w-fit">
        <div className="flex gap-4">
          <Search
            placeholder={t("search")}
            allowClear
            disabled={isLoading}
            onSearch={(value) => {
              setSearch(value);
            }}
            style={{ width: 304 }}
          />
        </div>
        <Radio.Group
          disabled={isLoading}
          value={searchStatus}
          onChange={(e) => setSearchStatus(e.target.value)}
        >
          <Radio.Button value="ALL">{t("ALL")}</Radio.Button>
          <Radio.Button value="pp">{t("PP")}</Radio.Button>
          <Radio.Button value="pm">{t("PM")}</Radio.Button>
        </Radio.Group>
      </div>

      <Table
        rowKey="clientID"
        size="small"
        columns={columns}
        loading={isLoading}
        dataSource={dataToDisplay}
        expandable={{
          expandedRowRender,
        }}
        rootClassName="h-2/3 min-h-2/3 "
        bordered={true}
      />
    </div>
  );
}

export default ClientsListingContent;
